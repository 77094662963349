import store from '../../store/store'
import FacilityService from '@/utils/FacilityService'

/** FACILITY LOADER */
export default function (to) { // Don't call next in this context
  return new Promise((resolve, reject) => {
    // If route has a facility specified then load it
    // eslint-disable-next-line no-prototype-builtins
    if (to.params.hasOwnProperty('facility')) {
      // See if the facility is changing or isn't set
      if (!store.getters['facility/isSet'] || store.state.facility.id !== to.params.facility) {
        // Reset the area store
        store.dispatch('facility/resetFacility')

        // Update the facility
        store.dispatch('facility/set', to.params.facility)
          .then(() => {
            // Facility successfully fetched
            resolve()
          })
          .catch(e => {
            store.commit('alerts/newMessage', { type: 'error', message: e }, { root: true })

            // Unable to retrieve facility so go back to the home page.
            reject('/')
          })
      } else {
        resolve()
      }
    } else {
      // See if facility stored and try to load it
      const rememberFacility = FacilityService.getId()
      if (rememberFacility) {
        store.dispatch('facility/set', rememberFacility).then(() => {
          resolve()
        })
      }

      // No facility set so complete the navigation
      resolve()
    }
  })
}
