import setFacility from './setFacility'
import setArea from './setArea'

/** FACILITY LOADER */
export default (to, from, next) => {
  // Load dependencies if loading a route that needs a facility
  setFacility(to, from, next)
    .then(() => {
      setArea(to, from, next)
    })
    .catch(e => {
      next(e)
    })
}
