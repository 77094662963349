import './polyfills' // Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import Notifications from '@/components/stateless/NotificationPlugin' // Notifications plugin. Used on Notifications page
import VeeValidate from 'vee-validate' // Validation plugin used to validate forms
import GlobalComponents from './GlobalComponents' // A plugin file where you could register global components used across the app
import GlobalDirectives from './GlobalDirectives' // A plugin file where you could register global directives
import GlobalFilters from './GlobalFilters'
import GlobalMixins from './Mixins'
import SideBar from '@/components/stateless/SidebarPlugin'

/** element-ui language configuration */
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

/** Import assets */
import '@/assets/sass/now-ui-dashboard.scss'
import '@/assets/sass/app.scss' // Custom overrides
import 'es6-promise/auto' // lodash

window._ = require('lodash')
locale.use(lang) // library auto imports

export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(GlobalFilters)
    Vue.use(GlobalMixins)
    Vue.use(SideBar)
    Vue.use(Notifications)
    Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
  }
}
