import FacilityService from '../utils/FacilityService'
import { repositoryClass } from '@/repositories/RepositoryClass'

function repo () {
  return repositoryClass('Member Leave', `${FacilityService.getId()}/member_leave`)
}
export default {
  index () {
    return repo().get()
  },
  get (memberID, bidYear) {
    return repo().get('get', `${memberID}`, { bid_year: bidYear })
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  }
}
