/**
 * vue-click-outside
 * https://www.npmjs.com/package/v-click-outside
 */
import { directive as vClickOutside } from 'vue-clickaway'

const GlobalDirectives = {
  install (Vue) {
    Vue.directive('click-outside', vClickOutside)
    Vue.directive('focus', {
      inserted: function (el) {
        Vue.nextTick(() => el.focus())
      }
    })
  }
}

export default GlobalDirectives
