/** store/leave.js */
import Vue from 'vue'
import LeaveTypeRepository from '../repositories/leaveTypeRepository'
import MemberLeaveRepository from '../repositories/memberLeaveRepository'

const init = () => {
  return {
    daysAllowed: 0,
    weeksAllowed: 0,
    holidaysAllowed: 0,
    crewLimit: 0,
    requireConsecutiveDays: false,
    roundType: 'accrued'
  }
}

// State
const state = {
  types: {},
  hours: {},
  restrictions: init(),
  bidWeeks: [],
  showBidCart: false
  // daysAllowed: null,
}

const getters = {
  showBidCart: state => state.showBidCart,
  types: state => state.types,
  hours: state => state.hours,
  restrictions: state => state.restrictions,
  bidWeeks: state => state.bidWeeks,
  bids: state => _.flatten(_.map(state.bidWeeks, 'bids')),
  activeBidWeek: state => _.find(state.bidWeeks, { active: true }),
  weeksRemaining: (state, getters) => getters.restrictions.weeksAllowed - state.bidWeeks.length,
  weekBidsRemaining: (state, getters) => {
    // Make sure bidding is active
    if (!getters.activeBidWeek) return 0
    // return (getters.bidsRemaining > 5 ? 5 : getters.bidsRemaining) - getters.activeBidWeek.bids.length;
    return Math.min(getters.bidsRemaining, 5 - getters.activeBidWeek.bids.length)
  },
  bidsRemaining: (state, getters) => {
    return getters.restrictions.daysAllowed - getters.bids.length
  },
  hoursBid: (state, getters) => {
    const bidHours = _.sumBy(getters.bids, 'hours')
    // Check if holidays count against bidder
    if (!getters.restrictions.holidaysSubtractFromLeave) { return bidHours - getters.holidayHoursBid } else { return bidHours }
  },
  hoursAllowed: state => {
    return state.restrictions.graceHours + (state.restrictions.roundType === 'accrued' ? state.hours.balance_accrued : state.hours.balance)
  },
  hoursRemaining: (state, getters) => {
    return getters.hoursAllowed - getters.hoursBid
  },
  holidayHoursBid: (state, getters) => {
    return _.sumBy(_.filter(getters.bids, 'isHoliday'), 'hours')
  },
  holidaysBid: (state, getters) => {
    return _.filter(getters.bids, 'isHoliday').length
  },
  daysAllowed: (state, getters) => {
    return getters.activeBidWeek ? getters.activeBidWeek.daysAllowed : null
    // if (state.restrictions.requireConsecutiveDays &&
    //     getters.activeBidWeek &&
    //     getters.activeBidWeek.bids.length) {
    //
    //     // Make sure not exceeding week
    //     if (getters.activeBidWeek.days_in_week > 6)
    //         return false;
    //
    //     // Find previous and next shift
    //     let bids = getters.activeBidWeek.bids;
    //     return [
    //         bids[0].day.schedule.previous_shift_day,
    //         bids[bids.length - 1].day.schedule.next_shift_day,
    //     ]
    // } else {
    //     return null;
    // }
  }
}

const actions = {
  init ({ commit, state, rootGetters }, bidder) {
    return new Promise((resolve, reject) => {
      const restrictions = init()
      const round = rootGetters['round/rounds'][bidder.round_id]

      // Make sure round is active and exists
      if (!round || round.status !== 'active') {
        reject(new Error('No active round found for the bidder.'))
        return
      }

      // Don't bother if bidding line round
      if (round.round_type === 'line') {
        resolve()
        return
      }

      // Set up restrictions based on round
      restrictions.graceHours = rootGetters['area/area'].grace_hours
      restrictions.daysAllowed = round.allowed_days
      restrictions.weeksAllowed = Math.ceil(round.allowed_days / 5)
      restrictions.holidaysAllowed = round.holidays_allowed
      restrictions.holidaysSubtractFromLeave = rootGetters['area/area'].subtract_holiday_leave
      restrictions.accrualLimit = rootGetters['area/area'].accrual_hours_limit
      restrictions.accrualLimitFactor = rootGetters['area/area'].accrual_slot_factor
      restrictions.requireConsecutiveDays = round.require_consecutive_days
      restrictions.crewLimit = round.crew_limit
      restrictions.roundType = round.leave_type
      commit('updateRestrictions', restrictions)

      // Create default bid week
      if (!state.bidWeeks.length) { commit('newBidWeek') }

      resolve()
    })
  },
  bid ({ commit, state, getters, dispatch }, bid) {
    return new Promise((resolve, reject) => {
      const { days, day, slot } = bid

      // Create a new week if one doesn't exist
      const weekIndex = _.findIndex(state.bidWeeks, { active: true })
      if (weekIndex === -1) {
        // Make sure the bidder can bid an additional week
        if (state.bidWeeks.length >= state.restrictions.weeksAllowed) return false

        // Make the new week
        commit('newBidWeek', day.$d)
      }

      // Format the bid
      dispatch('newLeaveBid', { days, week: getters.activeBidWeek, day, slot })
        .then((m) => {
          resolve(m)
        })
        .catch((m) => {
          reject(m)
        })

      // Clean up empty week, if necessary
      // if (getters.activeBidWeek && !getters.activeBidWeek.bids.length && state.bidWeeks.length === 1)
      //     commit('deleteBidWeek', weekIndex);
    })
  },
  checkLeave ({ state, getters }, bid) {
    return new Promise((resolve, reject) => {
      const { day } = bid // day, week, slot available

      // Make sure days available to be selected for this week
      if (getters.weekBidsRemaining <= 0) {
        reject(new Error('Days for the active week exceeded'))
        return
      }

      // Make sure days available for round
      if (getters.bidsRemaining <= 0) {
        reject(new Error('Days for round exceeded.'))
        return
      }

      // Make sure bidder has enough leave to cover
      if (getters.hoursBid + day.schedule.shift_length > getters.hoursAllowed) {
        reject(new Error('Not enough hours available to cover this bid.'))
        return
      }

      // Verify holiday leave restrictions
      if (day.schedule.is_holiday && getters.holidaysBid >= state.restrictions.holidaysAllowed) {
        reject(new Error('Holiday limit for this round would be exceeded with this bid.'))
        return
      }

      // Verify Area Accrual available, if accrual round
      if (getters.restrictions.roundType === 'accrued' && day.schedule.shift_length + getters.hoursBid > getters.restrictions.accrualLimit + getters.restrictions.accrualLimitFactor) {
        reject(new Error('Area available accrual would be exceeded with this bid.'))
        return
      }

      resolve()
    })
  },
  newLeaveBid ({ commit, state, dispatch }, bid) {
    return new Promise((resolve, reject) => {
      const { week, day, slot } = bid
      const bidIndex = _.findIndex(week.bids, { id: slot.id })

      // See if adding or removing the bid
      if (bidIndex === -1) {
        // CREATE NEW BID
        // Check business rules
        dispatch('checkLeave', bid)
          .then(() => {
            // Update week
            const start = !week.range.start || day.$d.isBefore(week.range.start) ? day.$d : week.range.start
            const end = !week.range.end || day.$d.isAfter(week.range.end) ? day.$d : week.range.end
            const updates = {
              start,
              end,
              shift_length: day.schedule.shift_length,
              days_in_week: end ? end.diff(start, 'days') : 1
            }
            commit('updateBidWeek', { week, updates })

            // Save the bid
            commit('newBid', bid)

            // Update days allowed
            dispatch('updateDaysAllowed', bid)

            resolve('new_bid')
          })
          .catch(m => {
            commit('alerts/newMessage', { type: 'error', message: m }, { root: true })
            reject(m)
          })
      } else {
        // DELETE EXISTING BID
        // Make sure not removing day in middle of week if restricted
        const sortedIdx = _.findIndex(week.bids, { id: slot.id })
        if (state.restrictions.requireConsecutiveDays) {
          if (sortedIdx + 1 !== week.bids.length && sortedIdx !== 0) {
            reject(new Error('Unable to deselect a day in the middle of the week.'))
            return
          }
        }

        // If only bid for week then just delete the week
        if (week.bids.length === 1) {
          commit('deleteBidWeek', _.findIndex(state.bidWeeks, { active: true }))
          commit('newBidWeek')
          // commit('setDaysAllowed', null);
        } else {
          // Update the week since more than one bid
          const start = sortedIdx === 0 ? week.bids[1].$d : week.range.start
          const end = sortedIdx + 1 === week.bids.length ? week.bids[sortedIdx - 1].$d : week.range.end
          const updates = {
            start,
            end,
            shift_length: day.schedule.shift_length,
            days_in_week: end.diff(start, 'days')
          }
          commit('updateBidWeek', { week, updates })

          // Delete the bid
          commit('deleteBid', { week, idx: bidIndex })
        }

        resolve('delete_bid')
      }
    })
  },
  updateDaysAllowed ({ state, commit, getters }, payload) {
    const { days, week, day } = payload // slot

    // If bidding consecutive days then bid the entire week
    if (state.restrictions.requireConsecutiveDays && week.days_in_week === 1) {
      const dayIndex = _.findIndex(days, { d: day.d })
      let dayCount = 1
      const allowed = []

      // Loop through each day, starting with the clicked day
      for (let i = dayIndex + 1; i < dayIndex + 7; i++) {
        // Make sure bidder has a shift
        if (days[i] && days[i].schedule.shift && dayCount <= getters.weekBidsRemaining) {
          // See if any slots available for this day
          const slot = _.find(days[i].slots, s => {
            return s.canBidderBid && !s.bid
          })

          // Add day to allowed array, if it's available
          if (slot) {
            allowed.push(days[i].d)

            // Count this day towards the week total
            dayCount++
          }
        }
      }

      commit('setDaysAllowed', { week, allowed })
    }
  },
  fetchTypes ({ commit }) {
    return new Promise((resolve, reject) => {
      LeaveTypeRepository.get()
        .then((response) => {
          commit('types', response.data)
          resolve(response.data)
        })
        .catch(e => {
          reject(e.data)
        })
    })
  },
  fetchMemberLeave ({ commit, rootGetters }, memberID) {
    return new Promise((resolve, reject) => {
      MemberLeaveRepository.get(memberID, rootGetters['facility/bidYear'])
        .then((response) => {
          commit('setHours', response.data)
          resolve(response.data)
        })
        .catch(e => {
          reject(e.data)
        })
    })
  },
  prepareLeave ({ getters, commit, dispatch }) {
    commit('bidder/setLeave', _.map(getters.bids, bid => {
      const idx = _.findIndex(getters.types, { id: bid.slot.leaveTypeId })
      return {
        calendar_id: bid.day.d,
        is_holiday: bid.isHoliday,
        hours: bid.hours,
        leave_type_id: bid.slot.leaveTypeId,
        is_preapproved: getters.types[idx].is_preapproved,
        crew_id: bid.crewId
      }
    }), { root: true })

    // Reset the bid
    commit('reset')

    return dispatch('bidder/bid', null, { root: true })
  }
}

const mutations = {
  reset (state) {
    state.restrictions = init()
    state.bidWeeks = []
    state.hours = {}
    state.showBidCart = false
  },
  newBidWeek (state, day) {
    // Make sure previous week is no longer active
    _.forEach(state.bidWeeks, week => {
      week.active = false
    })

    // Create the new week
    const week = {
      active: true,
      range: {
        start: day ? day.clone() : null,
        end: day ? day.clone() : null
      },
      hoursBid: 0,
      days_in_week: 0,
      bids: [],
      daysAllowed: null
    }
    state.bidWeeks.push(week)
  },
  updateBidWeek (state, payload) {
    const { week, updates } = payload
    week.range.start = updates.start
    week.range.end = updates.end
    week.hoursBid += updates.shift_length
    week.days_in_week = updates.days_in_week + 1 // Do
  },
  newBid (state, payload) {
    const bid = {
      id: payload.slot.id,
      day: payload.day,
      $d: payload.day.$d,
      isHoliday: !!(payload.day.schedule.is_holiday || payload.day.schedule.is_holiday_ilo),
      hours: payload.day.schedule.shift_length,
      slot: payload.slot,
      crewId: payload.day.schedule.crew_ID
    }

    // Find the week and push the bids
    payload.week.bids.push(bid)
    payload.week.bids.sort((a, b) => a.$d.unix() - b.$d.unix())
    // _.find(state.bidWeeks, { active: true }).bids.push(bid);
  },
  deleteBid (state, payload) {
    payload.week.bids.splice(payload.idx, 1)
  },
  deleteBidWeek (state, idx) {
    // If there is a previous week, make it active
    if (idx > 0) { state.bidWeeks[idx - 1].active = true }

    // Remove the current week
    state.bidWeeks.splice(idx, 1)
  },
  types (state, types) {
    state.types = types
  },
  add (state, type) {
    state.types.push(type)
    // Vue.set(state.types, type.id, type);
  },
  remove (state, type) {
    const idx = _.findIndex(state.types, { id: type.id })
    state.types.splice(idx, 1)
    // delete state.types[type.id];
  },
  setDaysAllowed (state, payload) {
    const { week, allowed } = payload
    // state.daysAllowed = allowed;
    week.daysAllowed = allowed
  },
  setHours (state, hours) {
    state.hours = hours
  },
  toggleBidCart (state, set = null) {
    state.showBidCart = set !== null ? set : !state.showBidCart
  },
  toggleLeaveSlot (state, slot) {
    const i = _.indexOf(state.bid.leave, slot)
    if (i === -1) {
      state.bid.leave.push(slot)
    } else {
      state.bid.leave.splice(i, 1)
    }
  },
  update (state, type) {
    const idx = _.findIndex(state.types, { id: type.id })
    Vue.set(state.types, idx, type)
    // state.types[type.id] = type;
  },
  updateRestrictions (state, restrictions) {
    state.restrictions = restrictions
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
