import store from '../store/store'
import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('content', `${store.getters['facility/facility'].id}/contents`)
}
export default {
  get () {
    return repo().get()
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  }
}
