import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('facility', 'facilities')
}

export default {
  index () {
    return repo().get()
  },
  get (facilityID) {
    return repo().get('get', `${facilityID}`)
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  fetchEmployeeTypes (facilityID) {
    return repo().get('fetchEmployeeTypes', `${facilityID}/employee_types`)
  },
  activate (facilityID) {
    return repo().post({}, 'activateFacility', `${facilityID}/activate`)
  }
}
