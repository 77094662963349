const screenSize = {
  data () {
    return {
      // Identify initial screen width and save as prototype
      screenWidth: window.innerWidth,
      screenSize: this.screensize(window.innerWidth)
    }
  },
  methods: {
    /** Handle window resize event */
    windowResize () {
      const w = window.innerWidth
      this.screenWidth = w
      this.screenSize = this.screensize(w)
    },
    screensize (width) {
      return width < 576 ? 'xs'
        : width >= 576 && width < 768 ? 'sm'
          : width >= 768 && width < 992 ? 'md'
            : width >= 992 && width < 1200 ? 'lg'
              : width >= 1200 ? 'xl'
                : null
    },
    screenSizeIs (size) {
      // Make sure size is an array
      const sizes = _.isArray(size) ? size : [size]

      // See if the current size is one of the specified sizes
      return sizes.indexOf(this.screenSize) !== -1
    }
  },
  computed: {
    dialogWidth () {
      return (this.screenSize !== 'lg' && this.screenSize !== 'xl' ? 80 : 40) + '%'
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResize)
  }
}
export default screenSize
