import fgInput from '@/components/stateless/inputs/formGroupInput'
import Card from '@/components/stateless/Card'
import Button from '@/components/stateless/Button'
import { Input, InputNumber, Tooltip, Popover, MessageBox, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'

/**
 * V-Hotkey Directive
 * https://github.com/Dafrok/v-hotkey/blob/master/README.md
 */
import VueHotKey from 'v-hotkey'

const GlobalComponents = {
  install (Vue) {
    Vue.component('fg-input', fgInput)
    Vue.component('card', Card)
    Vue.component('n-button', Button)
    Vue.component(Input.name, Input)
    Vue.component(InputNumber.name, InputNumber)
    Vue.component(Dropdown.name, Dropdown)
    Vue.component(DropdownMenu.name, DropdownMenu)
    Vue.component(DropdownItem.name, DropdownItem)
    Vue.component(MessageBox.name, MessageBox)
    Vue.prototype.$msgbox = MessageBox
    Vue.prototype.$alert = MessageBox.alert
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$prompt = MessageBox.prompt
    Vue.use(Tooltip)
    Vue.use(Popover)
    Vue.use(VueHotKey)
    Vue.use(require('vue-pusher'), {
      api_key: process.env.VUE_APP_PUSHER_KEY,
      options: {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true
      }
    })
  }
}

export default GlobalComponents
