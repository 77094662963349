
// Axios.js
// Create axios object for use in project
import axios from 'axios'

// Create axios instance with configurations
const config = {
  headers: {
    'X-Request-With': 'XMLHttpRequest'
  },
  baseURL: '/api',
  withCredentials: true
}

// Create the axios instance
const client = axios.create(config)

export default client
