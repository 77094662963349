import FacilityService from '../utils/FacilityService'
import { repositoryClass } from './RepositoryClass'

// Create Repository
function repo () {
  return repositoryClass('area', `/${FacilityService.getId()}/areas`)
}

export default {
  get () {
    return repo().get()
  },
  fetchStats (slug, refresh = false) {
    return repo().post({ recalculate: refresh }, 'fetchStats', `${slug}/stats`)
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  reset (area) {
    return repo().post({ area_slug: area.slug }, 'reset', 'reset')
  }
}
