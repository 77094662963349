import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('auth', '')
}

export default {
  csrf () {
    return repo().get('csrf', 'sanctum/csrf-cookie')
  },
  getUser (userID = null, withMember = false) {
    return repo().get('getUser', 'get-user', { user_id: userID, with_member: withMember }, true)
  },
  getUserById (memberID) {
    return repo().get('getUserById', `get-user/${memberID}`)
  },
  getScope (facilityID, userID = null) {
    return repo().get('getScope', `${facilityID}/get-scope`, { user_id: userID })
  },
  getActivity (userID, page) {
    // Establish page number
    const param = page !== 1 ? `?page=${page}` : ''

    return repo().get('getActivity', `activity/${userID}${param}`)
  },
  login (payload) {
    return repo().post(payload, 'login', 'login')
  },
  logout () {
    return repo().post(null, 'logout', 'logout')
  },
  register (payload) {
    return repo().post(payload, 'register', 'register')
  },
  requestResetCode (email) {
    return repo().post({ email }, 'requestResetCode', 'request-code')
  },
  resetPassword (payload) {
    return repo().post(payload, 'resetPassword', 'reset-password')
  },
  lookupBUE (payload) {
    return repo().post(payload, 'lookupBUE', 'lookup_bue')
  }
}
