import store from '@/store/store'
// import { getInstance } from '../../auth/index'

/**
 * Check if the current route requires a specific role, and whether the logged in user has that role
 *
 * @param to
 * @returns {boolean|*}
 */
function matchesScope (to) {
  // Filter required roles for current route
  const requiredRole = _.filter(to.matched.map(record => record.meta.auth), v => v !== undefined)

  // If role is required make sure the user has the role
  return !requiredRole.length || (requiredRole && store.getters['auth/hasRole'](to.params.facility, requiredRole))
}

/**
 * Check if route requires a login
 * @param to
 * @returns {*|boolean}
 */
function matchesLogin (to) {
  // See if route requires login
  const requiresLogin = _.filter(to.matched.map(record => record.meta.loggedIn), v => v === true)[0]

  // Check that user is logged in, if required
  return !requiresLogin || (requiresLogin && store.getters['auth/loggedIn'])
}

/** ROUTE AUTHENTICATION */
export default (to, from, next) => {
  Promise.all([
    store.dispatch('auth/getAuthUser'), // Fetch the user profile for logged in user - if there is one
    store.dispatch('auth/getScope', to.params.facility, { root: true }) // Fetch the user scope
  ])
    .then(() => {
      if (matchesScope(to)) {
        next()
      } else {
        store.commit('alerts/newMessage', {
          type: 'error',
          message: 'You do not have permission to view the requested page.'
        }, { root: true })
        next({ name: 'Facility Home', params: { facility: to.params.facility } })
      }
    })
    .catch(() => {
      if (matchesLogin(to) && matchesScope(to)) {
        next()
      } else {
        store.commit('alerts/newMessage', {
          type: 'info',
          message: 'You must log in to view the requested page.'
        }, { root: true })
        next({ name: 'Login' })
      }
    })
}
