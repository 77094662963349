import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import authenticateRoutes from './guards/authenticateRoutes'
import beforeResolve from './guards/beforeResolve'

Vue.use(VueRouter)

// Add link style class
routes.linkActiveClass = 'active'

// Create the router
const router = new VueRouter({
  routes
})

/** GUARDS */
router.beforeEach(authenticateRoutes) // Handle Authentication
router.beforeResolve(beforeResolve)

export default router
