import store from '../../store/store'

/** AREA LOADER */
export default function (to, from, next) { // Don't call next in this context
  // If navigating to a route with an area param then set the area
  // eslint-disable-next-line no-prototype-builtins
  if (to.params.hasOwnProperty('area')) {
    // See if area already set, or is changing
    if (!store.getters['area/isSet'] || store.state.area.slug !== to.params.area) {
      // Set the new area in the store
      store.dispatch('area/setArea', to.params.area)
        .then(() => { // Area successfully fetched
          // See if user needs to be logged in for this area
          if (store.getters['area/area'].require_login && !store.getters['auth/loggedIn']) {
            // User not logged in, send the message
            store.commit('alerts/newMessage', {
              type: 'error',
              message: 'The requested area requires users to be logged in to view.'
            }, { root: true })

            // Cancel navigation to this area
            store.commit('area/setArea', {})

            // Reject to home
            next({ name: 'Facility Home', params: { facility: to.params.facility } })
          } else {
            // No login required, proceed
            next()
          }
        })
        .catch(e => {
          // Unable to retrieve area so go back to the home page.
          store.commit('alerts/newMessage', {
            type: 'error',
            message: 'Unable to navigate to the requested area.\n\r' + e
          }, { root: true })
          next({ name: 'Facility Home', params: { facility: to.params.facility } })
        })
    } else {
      // Already set so resolve
      next()
    }
  } else {
    next()
  }
}
