/** repositories/bidderRepository.js */
import store from '../store/store'
import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('bidder', `${store.getters['facility/facility'].id}/${store.getters['area/area'].slug}/bidders`)
}

export default {
  get (bidYear) {
    return repo().get('get', null, { bid_year: bidYear })
  },
  getMemberBidders (bidYear, memberID) {
    return repo().get('getMemberBidders', `member/${memberID}`, { bid_year: bidYear })
  },
  fetchEligibleBidders (bidderID, skipToEnd) {
    return repo().post({ skipToEnd }, 'fetchEligibleBidders', `eligible/${bidderID}`)
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload, 'update', null, true)
  },
  delete (id) {
    return repo().del(id)
  }
  // Unused during refactoring 02/04/22
  // bid (payload) {
  //   return repo().post(payload, 'bid', '/bid')
  //     .then(() => {
  //       repo().message('Bid saved successfully.')
  //     })
  // }
}
