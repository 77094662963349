const AREA_KEY = 'AreaId';

/** Try to determine the current area
 * Will be validated against API later by VUE/VUEX
 *
 * Idea from: https://medium.com/@zitko/structuring-a-vue-project-authentication-87032e5bfe16
 */
const AreaService = {
    get() {
        return JSON.parse(localStorage.getItem(AREA_KEY));
    },
    store(area) {
        localStorage.setItem(AREA_KEY, JSON.stringify(area));
    },
    clear() {
        localStorage.removeItem(AREA_KEY);
    }
};

export default AreaService;
