import moment from 'moment-timezone';

const timezone = {
    data() {
        return {};
    },
    computed: {
        tz() {
            return moment.tz.zone(moment.tz.guess()).abbr(moment());
        },
        utcOffset() {
            return moment.tz.zone(moment.tz.guess()).utcOffset(moment());
        },
    },
};
export default timezone;
