/** store/auth.js */
import AuthRepository from '../repositories/authRepository'

// System roles
// *** Removed 02/25/22 as unused constant
// const roles = [ // Order matters, user at bottom of list has all preceding roles automatically
//   'guest',
//   'controller',
//   'bidaid',
//   'arearep',
//   'admin',
//   'super'
// ]

const roleNames = {
  guest: 'Guest',
  controller: 'Controller',
  bidaid: 'Bidder',
  arearep: 'Area Rep',
  admin: 'Facility Rep',
  super: 'Super User'
}

// Initial state for store
const init = () => {
  return {
    roleNames,
    scope: {},
    status: 'init',
    user: null,
    mocking: false
  }
}

// State
const state = init()

const getters = {
  loggedIn: state => state.status === 'logged-in',
  isLoggedInUser: (state) => (memberID) => {
    return state.user.member_id === memberID
  },
  roles: state => state.scope.roles,
  isScopeSet: state => (facilityID) => {
    return state.scope.facility_id === facilityID && !!state.scope.user_id
  },
  hasRole: (state, getters) => (facilityID, roles) => {
    // Make sure roles is an array
    const requestedRoles = roles instanceof Array ? roles : [roles]
    return getters.isScopeSet(facilityID) && _.intersection(requestedRoles, state.scope.roles).length > 0
  },
  is: (state) => (role, areaId = null) => {
    // Make sure user logged in
    if (!state.user) { return false }

    // Validate the role and scope
    const areaCheck = areaId ? state.scope.areas.includes(areaId) : true // Check area scope, if specified
    // roleIdx = state.scope.roles.indexOf(state.user.role_id),
    // roles = state.scope.roles.slice(0, roleIdx + 1);

    // Check role and scope
    if (state.user.role_id === 'super') {
      return true // Super always allowed
    } else if (!state.scope.facility) {
      return false // Everyone else must be in their facility
    } else {
      return state.scope.roles.includes(role) && areaCheck
    }
  },
  mocking: state => state.mocking,
  roleName: (state) => (role) => {
    return state.roleNames[role]
  },
  roleNames: state => state.roleNames,
  role: state => state.roleNames[state.user.role_id],
  user: state => state.user
}

const actions = {
  // checkUser ({ commit, dispatch, state }) {
  //   return new Promise((resolve, reject) => {
  //     // Only check user if user not loaded
  //     if (state.status !== 'init') {
  //       resolve()
  //       return
  //     }
  //
  //     // See if user logged in
  //     AuthRepository.getUser()
  //       .then(r => {
  //         // Not logged in, so do it
  //         dispatch('setUser', r.data)
  //         resolve(r.data)
  //       })
  //       .catch(e => {
  //         // If not found then reset state
  //         commit('logout')
  //         commit('status', 'logged-out')
  //
  //         // Reject the promise
  //         reject(e)
  //       })
  //   })
  // },
  // getUserByMemberId ({ commit, dispatch, state }, memberId) {
  //   return new Promise((resolve, reject) => {
  //     // Fetch the user
  //     AuthRepository.getUserById(memberId)
  //       .then(r => {
  //         // Not logged in, so do it
  //         dispatch('setUser', r.data)
  //         resolve(r.data)
  //       })
  //       .catch(e => {
  //         // Reject the promise
  //         reject(e)
  //       })
  //   })
  // },
  setUser ({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      // Store the logged in user
      commit('setUser', user)

      // Fetch the member record for the user
      dispatch('member/fetch', user.member_id, { root: true })
        .then(r => {
          commit('status', 'logged-in')
          resolve({ user, member: r.data })
        })
        .catch(e => {
          // Store the error status
          commit('status', 'error')

          // Send the error message
          commit('alerts/newMessage', {
            type: 'error',
            message: 'There was an error retrieving the MEMBER profile, please contact support for assistance.'
          }, { root: true })

          reject(e)
        })
    })
  },
  logout ({ commit }) {
    // Reset the auth state
    commit('logout')

    // Confirmation message
    commit('alerts/newMessage', {
      type: 'success',
      message: 'You have been successfully logged out of the system'
    }, { root: true })
  },
  mockUser ({ commit, dispatch }, payload) {
    // Handle mocking
    commit('mocking')

    // See if cancelling mocking
    if (payload.cancel) {
      AuthRepository.getUser()
        .then(r => {
          dispatch('setUser', r.data)
        })
    } else {
      // Change the user
      dispatch('setUser', payload.user)
        .then(r => {
          const { member } = r
          dispatch('getScope', member.facility_id)
        })
    }
  },
  // Fetch the authenticated user record
  getAuthUser ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      // Only call API one time
      if (state.status !== 'init') {
        return resolve()
      }

      // Fetch the user from the API
      AuthRepository.getUser()
        .then(r => {
          // Check if a user returned
          if (r.data.member_id !== undefined) {
            // Store the returned user
            dispatch('setUser', r.data)
          } else {
            // No user returned so update the status and move on
            commit('status', 'no-user')
          }

          resolve(r.data)
        })
        .catch(e => {
          // If there is an error then store the status - we don't know what's going on
          commit('status', 'no-user')

          // Send the error message
          commit('alerts/newMessage', {
            type: 'error',
            message: 'There was an error retrieving the USER profile, please contact support for assistance.'
          }, { root: true })
        })
    })
  },
  getScope ({ commit, getters }, facilityId) {
    return new Promise((resolve, reject) => {
      // If scope is already set then just resolve
      if (!facilityId || (getters.isScopeSet(facilityId) && !getters.mocking)) {
        return resolve()
      }

      // Scope not set so fetch it
      AuthRepository.getScope(facilityId, getters.mocking ? getters.user.id : null)
        .then(r => {
          commit('setScope', r.data)
          resolve()
        })
        .catch(r => {
          reject(r)
        })
    })
  }
}

const mutations = {
  mocking (state) {
    state.mocking = !state.mocking
  },
  status (state, status) {
    state.status = status
  },
  setUser (state, user) {
    state.user = user
  },
  logout (state) {
    Object.assign(state, init())
  },
  setScope (state, scopes) {
    state.scope = scopes
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
