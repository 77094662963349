import AreaRepository from '../repositories/areaRepository'
import CrewRepository from '../repositories/crewRepository'
import AreaService from '@/utils/AreaService'

/** store/area.js */

const init = () => {
  return {
    area: {},
    areas: [],
    crews: [],
    stats: {}
  }
}

// State
const state = init()

const getters = {
  area: state => state.area,
  slug: state => id => {
    const area = _.find(state.areas, { id })
    return area ? area.slug : null
  },
  areas: state => state.areas,
  crews: state => state.crews,
  isSet: state => !!state.area.id,
  is: (state) => (slug) => {
    return state.area.slug === slug
  },
  name: (state) => (areaId) => {
    return _.find(state.areas, { id: areaId })
  },
  stats: state => state.stats
}

const actions = {
  fetchAreas ({ commit, dispatch }) {
    // Fetch the areas
    return new Promise((resolve, reject) => {
      AreaRepository.get()
        .then(r => {
          commit('setAreas', r.data)

          // If only one area set it
          if (r.data.length === 1) {
            dispatch('setArea', r.data[0].slug)
          }

          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setArea ({ commit, state, dispatch }, slug) {
    return new Promise((resolve, reject) => {
      // Area not changing so just return the current area
      if (state.area.slug === slug) {
        resolve(state.area)
        return
      }

      // Area is changing so find the area already in storage
      const area = state.areas.find(area => area.slug === slug)
      if (area && area.id !== undefined) {
        // Set the area
        commit('setArea', area)

        // Save to local storage
        AreaService.store({ facility_id: area.facility_id, area_slug: area.slug })

        // Load area dependencies
        Promise.all([
          dispatch('fetchStats'),
          dispatch('leave/fetchTypes', null, { root: true }),
          dispatch('fetchCrews'),
          dispatch('round/fetch', null, { root: true }),
          dispatch('bidder/fetch', null, { root: true })
          // dispatch('bidder/fetchMemberBidder', rootGetters['member/id'], {root: true}),

        ])
          .then(() => {
            resolve(area)
          })
          .catch(e => {
            // Delete from local storage and state
            commit('setArea', {})
            AreaService.clear()
            reject(e)
          })
      } else {
        // No area so reject
        reject(new Error("Area doesn't exist."))
      }
    })
  },
  fetchCrews ({ commit }) {
    return new Promise((resolve, reject) => {
      CrewRepository.get()
        .then((response) => {
          commit('crews', response.data)
          resolve(response.data)
        })
        .catch(e => {
          reject(e.data)
        })
    })
  },
  fetchStats ({ commit, state }, refresh = false) {
    return new Promise((resolve, reject) => {
      AreaRepository.fetchStats(state.area.slug, refresh)
        .then((response) => {
          commit('stats', response.data)
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  }
}

const mutations = {
  setArea (state, area) {
    state.area = area
  },
  setAreas (state, areas) {
    state.areas = areas
  },
  reset (state) {
    Object.assign(state, init())
  },
  crews (state, crews) {
    state.crews = crews
  },
  stats (state, stats) {
    state.stats = stats
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
