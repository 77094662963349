import Axios from '../utils/Axios'
import store from '../store/store'

// Import the Auth0 configuration
import { Auth0Client } from '@auth0/auth0-spa-js'

const auth0 = new Auth0Client({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: { audience: process.env.VUE_APP_AUTH0_AUDIENCE }
})

// Use Axios Interceptor to attach the Auth0 Access Token to all requests
const setAxiosTokenInterceptor = async () => {
  // Check to see if there is a valid token in storage
  // Fetch the token
  await auth0.getTokenSilently()
    .then(token => {
      // Set the header for all requests
      Axios.interceptors.request.use(async config => {
        const requestConfig = config
        if (token) {
          requestConfig.headers.Authorization = `Bearer ${token}`
        }
        return requestConfig
      })
    })
    .catch(e => {
      console.log(e)
    })
}

// Use Axios Interceptor to catch when user is no longer logged in and redirect
Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
  // If the token is expired then catch it
    if (error.response.status === 401) {
      window.location.href = '/'
    }

    return Promise.reject(error)
  })

export function repositoryClass (model, baseUrl) {
  function message (message, type = 'error') {
    store.commit('alerts/newMessage', { type, message }, { root: true })
  }

  /**
   * Default GET action
   * @param action
   * @param payload
   * @param path - exclude leading slash
   * @param preventMessage - boolean to stop messaging
   * @param preventWait - boolean disaables the store/wait for call to complete (spinning wheel)
   * @param config - allow addition of configuration items for the Axios call
   * @returns {Promise<unknown>}
   */
  function get (action = 'get', path = null, payload = {}, preventMessage = false, preventWait = false, config = {}) {
    // Prevent wait indicator for site (spinning wheel) for this action
    if (!preventWait) { store.commit('request', `${model}/${action}`) }

    return setAxiosTokenInterceptor()
      .then(() => {
        return Axios.get(baseUrl + (path ? '/' + path : ''), { ...{ params: payload }, ...config })
          .then(r => {
            return r
          })
          .catch(e => {
            if (!preventMessage) message(e.response.data)
            throw e.response.data
          })
          .finally(r => {
            // Prevent wait indicator for site (spinning wheel) for this action
            if (!preventWait) {
              store.commit('request', `${model}/${action}`)
            }
            return r
          })
      })
  }

  /**
   * Create a record with the specified ID. Also used generically for POST
   * @param payload
   * @param action
   * @param path
   * @param preventMessage - boolean disables any automatic messaging
   * @param preventWait - boolean disaables the store/wait for call to complete (spinning wheel)
   * @param config - allow addition of configuration items for the Axios call
   * @returns {Promise<unknown>}
   */
  function post (payload, action = 'create', path = null, preventMessage = false, preventWait = false, config = null) {
    // Prevent wait indicator for site (spinning wheel) for this action
    if (!preventWait) { store.commit('request', `${model}/${action}`) }

    return setAxiosTokenInterceptor()
      .then(() => {
        return Axios.post(baseUrl + (path ? '/' + path : ''), payload, config)
          .then(r => {
            if (action === 'create' && !preventMessage) {
              message(`${model[0].toUpperCase()}${model.slice(1)} created successfully.`, 'success')
            }
            return r
          })
          .catch(e => {
            if (!preventMessage) {
              message(e.response.data)
            }
            throw e.response.data
          })
          .finally(r => {
            // Prevent wait indicator for site (spinning wheel) for this action
            if (!preventWait) {
              store.commit('request', `${model}/${action}`)
            }
            return r
          })
      })
  }

  /**
   * Update a record with specified ID
   * @param action
   * @param id - ID of record to update, appended to path
   * @param payload
   * @param path
   * @param preventMessage - boolean disables any automatic messaging
   * @param preventWait - boolean disaables the store/wait for call to complete (spinning wheel)
   * @param config - allow addition of configuration items for the Axios call
   * @returns {Promise<unknown>}
   */
  function put (id, payload, action = 'update', path = null, preventMessage = false, preventWait = false, config = null) {
    // Prevent wait indicator for site (spinning wheel) for this action
    if (!preventWait) { store.commit('request', `${model}/${action}`) }

    return setAxiosTokenInterceptor()
      .then(() => {
        return Axios.put(baseUrl + (path ? '/' + path : '') + (id ? `/${id}` : ''), payload, config)
          .then(r => {
            if (action === 'update' && !preventMessage) {
              message(`${model[0].toUpperCase()}${model.slice(1)} updated successfully.`, 'success')
            }
            return r
          })
          .catch(e => {
            if (!preventMessage) {
              message(e.response.data)
            }
            throw e.response.data
          })
          .finally(r => {
            // Prevent wait indicator for site (spinning wheel) for this action
            if (!preventWait) {
              store.commit('request', `${model}/${action}`)
            }
            return r
          })
      })
  }

  /**
   * Delete a REST record
   * @param action
   * @param id - ID of record to delete, appended to end of path
   * @param path
   * @param preventMessage - boolean disables any automatic messaging
   * @param preventWait - boolean disaables the store/wait for call to complete (spinning wheel)
   * @param config - allow addition of configuration items for the Axios call
   * @returns {Promise<unknown>}
   */
  function del (id, action = 'delete', path = null, preventMessage = false, preventWait = false, config = null) {
    // Prevent wait indicator for site (spinning wheel) for this action
    if (!preventWait) { store.commit('request', `${model}/${action}`) }

    return setAxiosTokenInterceptor()
      .then(() => {
        return Axios.delete(baseUrl + (path ? '/' + path : '') + `/${id}`, config)
          .then(r => {
            if (action === 'delete' && !preventMessage) {
              message(`${model[0].toUpperCase()}${model.slice(1)} deleted successfully.`, 'success')
            }
            return r
          })
          .catch(e => {
            if (!preventMessage) {
              message(e.response.data)
            }
            throw e.response.data
          })
          .finally(r => {
            // Prevent wait indicator for site (spinning wheel) for this action
            if (!preventWait) {
              store.commit('request', `${model}/${action}`)
            }

            return r
          })
      })
  }

  return {
    message,
    get,
    post,
    put,
    del
  }
}
