/** router/area.js */
const AreaHome = () => import('@/views/area/Area.vue')
const Layout = () => import('@/views/Layout/_Layout.vue')
const Header = () => import('@/views/Layout/_Header.vue')
const Areas = () => import('@/views/area/Areas.vue')
const Lines = () => import('@/views/line/Lines.vue')
const Leave = () => import('@/views/leave/Leave.vue')
const Roster = () => import('@/views/roster/Roster.vue')
const Round = () => import('@/views/round/Round.vue')
const Summary = () => import('@/views/area/Summary.vue')
// const Users = () => import('src/views/Admin/Users.vue');
const Member = () => import('@/views/member/Member.vue')
// const Members = () => import('src/views/Admin/Members.vue');

export const AdminAreas = {
  path: '/:facility/areas',
  component: Layout,
  meta: {
    auth: 'admin',
    requireLogin: true
  },
  children: [
    {
      path: '',
      name: 'Admin Areas',
      components: { default: Areas, header: Header }
    }
  ]
}

export const Area = {
  path: '/:facility/:area',
  component: Layout,
  children: [
    {
      path: '',
      name: 'Area Home',
      components: { default: AreaHome, header: Header }
    },
    {
      path: 'lines',
      name: 'Lines',
      components: { default: Lines, header: Header }
    },
    {
      path: 'leave',
      name: 'Leave',
      components: { default: Leave, header: Header }
    },
    {
      path: 'roster',
      name: 'Roster',
      components: { default: Roster, header: Header }
    },
    {
      path: 'round',
      name: 'Round',
      components: { default: Round, header: Header }
    },
    {
      path: 'bidder/:member_id',
      name: 'Area Bidder Details',
      components: { default: Member, header: Header }
    },
    {
      path: 'summary',
      name: 'Bid Summary',
      components: { default: Summary, header: Header }
    }
  ]
}

/** Try to move ADMIN functions to respective pages instead of separate menu
 {
            path: '/:area/admin',
            component: Layout,
            //name: 'Area Admin', // Used for Area Dropdown, needs to be removed to fix Vue alert about named parent
            // redirect: '/dashboard',
            meta: {
                auth: 'arearep',
            },
            beforeEnter: (to, from, next) => {
                area.set(to.params.area);

                // Wait for area to load
                area.promise.then(() => {
                    next();
                });
            },
            children: [
                        {
                            path: '',
                            name: 'Area Dashboard',
                            components: {default: AdminArea, header: Header}
                        },
                        {
                            path: 'lines',
                            name: 'Build Lines',
                            components: {default: Lines, header: Header}
                        },
                        {
                            path: 'leave',
                            name: 'Build Leave Slots',
                            components: {default: Leave, header: Header}
                        },
                {
                    path: 'rounds',
                    name: 'Manage Rounds',
                    components: {default: Round, header: Header}
                },
                        {
                            path: 'rosters',
                            name: 'Build Rosters',
                            components: {default: Roster, header: Header}
                        },
                        {
                            path: 'members',
                            name: 'Manage Area Members',
                            components: {default: Members, header: Header}
                        },
                        {
                            path: 'user',
                            name: 'Manage Users',
                            components: {default: Users, header: Header}
                        },
                {
                    path: 'messages',
                    name: 'Messages',
                    components: {default: Messages, header: Header}
                },
                {
                    path: 'summary',
                    name: 'Bid Summary',
                    components: {default: Summary, header: Header}
                },
                {
                    path: 'areas',
                    name: 'Admin Areas',
                    components: {default: Areas, header: Header}
                },
            ]
        },

 */
