import store from '@/store/store'
import { Area, AdminAreas } from './area'
import Facility from './facility'
import Members from './members'
import FacilityService from '@/utils/FacilityService'
import AreaService from '@/utils/AreaService'

/** HOME */
const Layout = () => import('@/views/home/Layout.vue')
const Header = () => import('@/views/home/HeaderHome.vue')
const Home = () => import('@/views/home/Home.vue')

export default [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        beforeEnter: (to, from, next) => {
          // Try to set facility from storage, otherwise just load the home page
          const facilityId = FacilityService.getId()

          // Redirect to the last state if they aren't here on purpose
          // eslint-disable-next-line no-prototype-builtins
          if (facilityId && !from.params.hasOwnProperty('facility')) {
            // Retrieve area from local storage
            const area = AreaService.get() // { facility_id, area_slug }

            // Check if area known
            if (area && area.facility_id === facilityId) {
              next({ name: 'Area Home', params: { facility: facilityId, area: area.area_slug } })
            } else {
              next({ name: 'Facility Home', params: { facility: facilityId } })
            }
          } else {
            next()
          }
        },
        components: { default: Home, header: Header }
      }
    ]
  },
  {
    path: '/logout',
    beforeEnter: (to, from, next) => {
      store.dispatch('auth/logout')
      next('/')
    }
  },
  Facility,
  AdminAreas,
  Area,
  Members
]
