const FACILITY_KEY = 'FacilityId';

/** Try to determine the current facility, simple regex to check for three letter facility ID
 * Will be validated against API later by VUE/VUEX
 *
 * Idea from: https://medium.com/@zitko/structuring-a-vue-project-authentication-87032e5bfe16
 */
const FacilityService = {
    getId() {
        return localStorage.getItem(FACILITY_KEY);
    },
    storeFacility(FacilityId) {
        localStorage.setItem(FACILITY_KEY, FacilityId);
    },
    clearFacility() {
        localStorage.removeItem(FACILITY_KEY);
    }
};

export default FacilityService;
