/** store/facility.js */
import FacilityRepository from '../repositories/facilityRepository'
import ContentRepository from '../repositories/contentRepository'
import FacilityService from '../utils/FacilityService'
import moment from 'moment'

// Initial state for store
const init = () => {
  return {
    id: '',
    facility: {},
    content: '',
    employeeTypes: [],
    bidYear: null,
    viewYear: null
  }
}

// State
const state = init()

const getters = {
  isSet: state => !!state.id,
  facility: state => state.facility,
  bidYear: state => state.bidYear,
  viewYear: state => state.viewYear,
  viewingPastYear: state => state.bidYear !== state.viewYear,
  employeeTypes: state => state.employeeTypes,
  employeeType: (state) => (type) => {
    return _.find(state.employeeTypes, { id: type })
  },
  leaveYearStart: state => {
    return moment.utc(state.facility.year.firstDay)
  },
  leaveYearEnd: state => {
    return moment.utc(state.facility.year.lastDay)
  },
  allowText: (state, getters) => {
    // Texting allowed is stored in facility model
    return state.facility.allow_text
  },
  checkPrivacy: (state, getters, rootState, rootGetters) => {
    // No privacy required so just return
    if (!state.facility.hide_all_content) return true

    // Privacy is on so make sure logged in
    return rootGetters['auth/loggedIn']
  },
  isFacilityOpen: state => {
    return !state.facility.auto_open_close || (state.facility.auto_open_close && state.facility.today.is_open_now)
  }
}

const actions = {
  set ({ commit, dispatch }, facilityId) {
    // Retrieve facility details
    return new Promise((resolve, reject) => {
      // Check that there is a facility ID
      if (!facilityId) {
        reject(new Error('No facility id'))
      }

      // Fetch the facility
      FacilityRepository.get(facilityId)
        .then(r => {
          // Make sure facility exists
          if (r === undefined || !r.data) {
            // Remove any reference to the facility
            FacilityService.clearFacility()
            reject(new Error('Facility ID is invalid and is not stored in database.'))
          } else {
            // Facility exists so set it
            commit('setFacility', r.data)

            // Set the facility ID in local storage
            FacilityService.storeFacility(facilityId)

            // Retrieve dependencies
            dispatch('setDependencies')
              .then(() => {
                resolve(r.data)
              })
              .catch(e => {
                console.log(e)
              }) // Ignore dependency errors, for now at least
          }
        })
    })
  },
  setDependencies ({ dispatch, state }) {
    // If changing facility then reset dependencies
    return Promise.all([
      // dispatch('auth/getScope', state.facility.id, { root: true }),
      dispatch('fetchContent'),
      dispatch('area/fetchAreas', null, { root: true }),
      dispatch('fetchEmployeeTypes')
    ])
  },
  resetFacility ({ commit }) {
    commit('area/reset', null, { root: true })
    commit('reset')
  },
  fetchContent ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // Check that there is a facility ID
      if (!state.id) {
        reject(new Error('No facility ID.'))
      }

      // ID exists so retrieve the contents
      ContentRepository.get(state.facility.id)
        .then(resp => {
          commit('setContent', resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateContent ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      ContentRepository.update(state.content.id, payload)
        .then(r => {
          commit('setContent', r.data)
          resolve(r.data)
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  fetchEmployeeTypes ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // Check that there is a facility ID
      if (!state.id) {
        reject(new Error('No facility ID.'))
        return
      }

      // ID exists so retrieve the contents
      FacilityRepository.fetchEmployeeTypes(state.facility.id)
        .then(resp => {
          commit('setEmployeeTypes', resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const mutations = {
  setFacility (state, facility) {
    state.id = facility.id
    state.facility = facility
    state.bidYear = facility.bid_year
    state.viewYear = facility.bid_year
  },
  setHours (state, today) {
    state.facility.today = today
  },
  setViewYear (state, bidYear) {
    state.viewYear = bidYear
  },
  setContent (state, content) {
    state.content = content
  },
  setEmployeeTypes (state, types) {
    state.employeeTypes = types
  },
  reset (state) {
    Object.assign(state, init())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
