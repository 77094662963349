<template>
  <span>
       <loading :active.sync="$store.getters['loading']"
                :is-full-page="true"
                loader="bars"
                background-color="#fff"
                :height="100"
                :width="100"
                :opacity="0.5"
                color="#698CB3"></loading>

       <router-view></router-view>
  </span>

</template>

<script>
    import {mapState} from 'vuex';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            Loading,
        },
        data() {
            return {}
        },
        methods: {},
        computed: {
            ...mapState({
                member: 'member/member',
            }),
        },
        created() {},
        watch: {
            $route(to, from) {
                document.title = 'bidATC :: ' + to.name || 'bidATC';
            }
        }
    }
</script>
