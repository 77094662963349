import screenSize from "./mixins/screenSize";
import utcDate from "./mixins/utcDate";
import utcTime from "./mixins/utcTime";
import timezone from "./mixins/timezone";

const GlobalMixins = {
    install(Vue) {
        Vue.mixin(screenSize);
        Vue.mixin(utcDate);
        Vue.mixin(utcTime);
        Vue.mixin(timezone);
    }
};

export default GlobalMixins;
