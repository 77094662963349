import moment from 'moment-timezone';

const utcDate = {
    data() {
        return {};
    },
    methods: {
        /** Convert local time to UTC time for storing in DB */
        utcTimes(bidHours, timezone, fields = ['bid_start', 'bid_end'], decode = false) {
            let offset = moment.tz(timezone).utcOffset();

            // Convert the time
            let change = (time) => {
                let t = moment(time, "HH:mm");
                return moment().hour(t.hour()).minute(t.minute()).second(0).subtract(offset * (decode ? -1 : 1), 'minutes').format("HH:mm");
            };

            return _.each(bidHours, item => {
                item[fields[0]] = ( item[fields[0]] ? change(item[fields[0]]) : null );
                item[fields[1]] = ( item[fields[0]] ? change(item[fields[1]]) : null );
            });
        },
    },
};
export default utcDate;
