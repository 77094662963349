/** store/bidders.js */
import BidderRepository from '../repositories/bidderRepository'
import BidRepository from '../repositories/bidRepository'
import moment from 'moment'
import MemberRepository from '../repositories/memberRepository'

// Initialize the bid object
const init = () => {
  return {
    bidder_id: null,
    user_id: null,
    lines: [],
    line_complete: false,
    leave: [],
    leave_complete: false,
    skip: false,
    skip_type: null
  }
}

// State
const state = {
  bidders: [],
  member: [],
  bid: init()
}

const getters = {
  active: state => _.find(state.bidders, { active: 1 }),
  bidders: state => state.bidders,
  bidMode: (state, getters, rootState, rootGetters) => {
    return getters.active && rootGetters['auth/user'] && getters.active.active_by === rootGetters['auth/user'].member_id
  },
  bidReady: state => state.bid.line_complete || state.bid.leave_complete,
  // 201026-Deprecated and removed. Created ambiguity potentially returning round for wrong bidder.
  // Put onus on component to fetch round
  // round: (state, getters, rootState, rootGetters) => {
  //     // See if there is an active round for this bidder
  //     return _.find(rootGetters['round/rounds'], { id: getters.active.round_id });
  // },
  roundBidders: state => (roundID) => {
    const rounds = _.groupBy(state.bidders, 'round_id')
    return rounds[roundID] ? _.groupBy(rounds[roundID], 'roster_team_id') : {}
  },
  member: state => (memberID) => { return _.filter(state.bidders, { memberID }) },
  linesBid: state => _.keyBy(state.bid.lines, 'line_group_id'),
  leaveBid: state => state.bid.leave
}

const actions = {
  bid ({ commit, state, getters, dispatch }, useBidder = null) {
    // return new Promise((resolve, reject) => {
    // Prepare the bid payload
    const bidder = useBidder || getters.active
    const payload = JSON.parse(JSON.stringify(state.bid)) // Copy the bid object
    payload.bidder_id = bidder.id

    return BidRepository.create(payload)
      .then(r => {
        // Reset the bid
        commit('initializeBidObject')

        // Update the rounds
        dispatch('round/fetch', null, { root: true })

        // Return the updated bidder
        return r.data
      })
      .catch(err => {
        // reject(err);
        return err
      })
      .finally(() => {
        dispatch('endActiveBidder', bidder)
      })
    // });
  },
  fetch ({ commit, dispatch, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      BidderRepository.get(rootGetters['facility/bidYear'])
        .then(r => {
          // Update the state
          commit('setBidders', r.data)

          // Prepare active bidder in state, if they exist
          if (!_.isEmpty(getters.active)) {
            // See if the user is the bidder or bidding on behalf
            if (getters['auth/user'] && getters.active.active_by === getters['auth/user'].member_id) {
              // Set the active bidder and allow bidding
              dispatch('startActiveBidder', getters.active)
            } else {
              // Only start the timer and display the information
              dispatch('startBidderTimer', getters.active)
            }
          }

          // Load bidders
          resolve(r)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  fetchActiveBidderSchedule ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      MemberRepository.getMemberSchedule(getters.active.member_id)
        .then((response) => {
          commit('setActiveSchedule', { id: getters.active.id, schedule: response.data })
          resolve(response.data)
        })
        .catch(e => {
          reject(e.data)
        })
    })
  },
  setActiveBidder ({ commit, dispatch, rootGetters }, bidder) {
    return new Promise((resolve, reject) => {
      // Check if the bidder is already active
      if (!bidder.active) {
        const payload = {
          bidder_id: bidder.id,
          changes: {
            active: 1,
            active_by: rootGetters['auth/user'].member_id,
            active_at: moment.utc()
          }
        }
        return dispatch('update', payload)
          .then(bidder => {
            // Update bidder into state
            commit('updateBidder', payload)
            resolve(bidder)
          })
          .catch(() => {
            reject(new Error('Unable to lock the area, already locked.'))
          })
      } else {
        resolve(bidder)
      }
    })
  },
  /**
     * Set the current bidder and fetch dependencies for entering the bid. Only used for bidder and bidding on behalf
     * @param getters
     * @param dispatch
     * @param bidder
     * @returns {Promise<unknown>}
     */
  startActiveBidder ({ getters, dispatch }, bidder) {
    return new Promise((resolve, reject) => {
      // See if there is already an active bidder
      if (getters.active && getters.active.id !== bidder.id) {
        reject(new Error('Unable to lock the area, already locked by ' + getters.active.full_name))
      }

      // Set the active bidder
      dispatch('setActiveBidder', bidder)
        .then(updatedBidder => {
          // Set up dependencies
          return Promise.all([
            dispatch('leave/init', bidder, { root: true }),
            dispatch('leave/fetchMemberLeave', bidder.member_id, { root: true }), // Check if leave round?
            dispatch('fetchActiveBidderSchedule')
          ])
            .then(() => {
              resolve(updatedBidder)
            })
            .catch(e => {
              reject(e)
            })
        })
    })
  },
  /**
     * Start the timer for the bid lock. Used for all users, displays the bid timer for info purposes.
     *
     * @param commit
     * @param dispatch
     * @param rootGetters
     * @param bidder
     */
  startBidderTimer ({ commit, dispatch, rootGetters }, bidder) {
    // Start the timer ** Need to check for running timer? **
    const payload = {
      start: bidder.active_at instanceof moment ? bidder.active_at : moment.utc(bidder.active_at),
      duration: rootGetters['facility/facility'].bid_lock_time
    }
    dispatch('countdown/start', payload, { root: true })
      .catch((m) => {
        // Unable to start timer let them know why
        commit('alerts/newMessage', {
          type: 'error',
          message: 'Inactive bidder. \n\r' + m
        }, { root: true })
      })
      .finally(() => {
        // Reset leave bidding
        commit('leave/reset', null, { root: true })

        // Disable active status for the bidder
        dispatch('endActiveBidder', bidder)
      })
  },
  endActiveBidder ({ dispatch, commit }, bidder) {
    const payload = {
      bidder_id: bidder.id,
      changes: {
        active: 0,
        active_by: null,
        active_at: null
      }
    }
    return Promise.all([
      dispatch('countdown/stop', null, { root: true }),
      dispatch('update', payload)
    ])
      .then(r => {
        commit('updateBidder', payload)
        return r
      })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BidderRepository.update(payload.bidder_id, payload.changes)
        .then(r => {
          // Update the state
          commit('updateBidder', payload)
          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  /** Refresh all bidder records for the member */
  refresh ({ commit, rootGetters }, memberID) {
    BidderRepository.getMemberBidders(rootGetters['facility/bidYear'], memberID)
      .then(r => {
        _.forEach(r.data, bidder => {
          const changes = { bidder_id: bidder.id, changes: bidder }
          commit('updateBidder', changes)
        })
      })
  }
}

const mutations = {
  initializeBidObject (state) {
    state.bid = init()
  },
  toggleLine (state, line) {
    const i = _.findIndex(state.bid.lines, { id: line.id })
    if (i === -1) {
      state.bid.lines.push(line)
    } else {
      state.bid.lines.splice(i, 1)
    }
  },
  setLeave (state, leave) {
    state.bid.leave = leave
    state.bid.leave_complete = true
  },
  skipBid (state, type) {
    state.bid.skip = true
    state.bid.skip_type = type
  },
  bidModuleComplete (state, payload) {
    state.bid[payload.module + '_complete'] = payload.status
  },
  setActiveSchedule (state, payload) {
    _.find(state.bidders, { id: payload.id }).schedule = payload.schedule
  },
  setBidders (state, bidders) {
    _.forEach(bidders, bidder => { bidder.schedule = {} })
    state.bidders = bidders
  },
  setMemberBidder (state, bidders) {
    state.member = bidders
  },
  updateBidder (state, payload) {
    const i = _.findIndex(state.bidders, { id: payload.bidder_id })
    Object.assign(state.bidders[i], payload.changes)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
