import store from '../store/store'
import { repositoryClass } from '@/repositories/RepositoryClass'

function repo () {
  return repositoryClass('round', `${store.getters['facility/facility'].id}/${store.getters['area/area'].slug}/rounds`)
}
export default {
  index (bidYear) {
    return repo().get('index', null, { bid_year: bidYear })
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  createBidWindows (roundID, payload) {
    return repo().post(payload, 'createBidWindows', `${roundID}/windows`)
  },
  reset (roundID) {
    return repo().post(null, 'resetRound', `${roundID}/reset`)
  }
}
