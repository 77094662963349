/** store/store.js **/
import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

// Import modules
import alerts from './alerts'
import area from './area'
import auth from './auth'
import bidder from './bidder'
import countdown from './countdown'
import facility from './facility'
import leave from './leave'
import member from './member'
import round from './round'

// Setup Vuex
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

// Export the Store
const store = new Vuex.Store({
  state: {
    loading: []
  },
  getters: {
    loading: state => state.loading.length !== 0
  },
  mutations: {
    // Global loading indicator
    request (state, method) {
      const i = state.loading.indexOf(method)
      i !== -1 ? state.loading.splice(i, 1) : state.loading.push(method)
    }
  },
  actions: {},
  modules: {
    alerts,
    area,
    auth,
    bidder,
    countdown,
    facility,
    leave,
    member,
    round
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
Vue.prototype.$store = store

export default store
