/** store/auth.js */
import MemberRepository from '../repositories/memberRepository'

// State
const state = {
  member: '',
  scope: ''
}

const getters = {
  id: state => state.member.id,
  member: state => state.member,
  init: state => state.member.init,
  facility_id: state => state.member.facility_id,
  area_id: state => state.member.area_id
}

const actions = {
  fetch ({ commit }, memberId) {
    return new Promise((resolve, reject) => {
      // Make sure memberId passed
      if (!memberId) {
        reject('No member number')
        return
      }

      // Get the member
      MemberRepository.getMember(memberId)
        .then(resp => {
          // Update the state
          commit('setMember', resp.data)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const mutations = {
  setMember (state, member) {
    state.member = member
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
