/** router/members.js */
import store from '@/store/store'
const Layout = () => import('@/views/Layout/_Layout.vue')
const Header = () => import('@/views/Layout/_Header.vue')
const Member = () => import('@/views/member/Member.vue')
const Members = () => import('@/views/member/Members.vue')

export default {
  path: '/:facility/members',
  component: Layout,
  children: [
    {
      path: 'all',
      name: 'Manage Members',
      meta: {
        auth: 'admin',
        requireLogin: true
      },
      components: { default: Members, header: Header }
    },
    {
      path: ':member_id',
      name: 'Controller Details',
      components: { default: Member, header: Header },
      beforeEnter: (to, from, next) => {
        if (to.params.member_id === store.state.auth.user.member_id || store.getters['auth/is']('arearep', store.state.area.area.id)) {
          next()
        } else {
          // Prevent editing member if no permissions
          next('/')
        }
      }
    }
  ]
}
