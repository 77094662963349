/** utils/filters/moment.js */
import moment from "moment-timezone";
import "twix";

export function utcDisplay(input, format = 'MM/DD/YYYY') {
    return moment.utc(input).format(format);
}
export function localDisplay(input, format = 'MM/DD/YYYY', abbreviation = false) {
    let d = moment.utc(input).local();
    return d.format(format) + (abbreviation ? ' ' + moment.tz.zone(moment.tz.guess()).abbr(d) : '');
}
export function dayRange(input, end, format = '') {
    return moment.twix(input, end, { allDay: true }).format(format);
}

export default { utcDisplay, dayRange, localDisplay }
