import moment from 'moment';

const utcDate = {
    data() {
        return {};
    },
    methods: {
        /**
         * Create js Date object ignoring local offset
         * Pass it a valid moment object
         * */
        vDatePickerUTCHack(m) {
            if (!moment.isMoment(m))
                m = moment.utc(m);
            let offset = new Date(m.toDate()).getTimezoneOffset();
            return m.clone().add(offset, 'minutes').toDate();
        },
    },
};
export default utcDate;
