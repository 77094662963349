/** store/countdown.js */
import moment from 'moment';

// State
const state = {
    status: 'stopped',
    now: moment.utc(),
    start: null,
    end: null,
    interval: null,
    // promise: null,
};

const getters = {
    remaining: state => {
        // Make sure timer running
        if (state.status !== 'running') return '00:00';

        // Get the time remaining
        let remaining =  moment.duration(state.end.diff(state.now));

        // Format the display time
        return ("0" + remaining.minutes()).substr(-2) + ':' + ("0" + remaining.seconds()).substr(-2);
    },
    status: state => state.status,
};

const actions = {
    /**
     * Start a timer. Send following parameters as payload
     * start: Moment object (or string that can be converted) for the start time of the timer
     * duration: Duration of the timer in minutes
     *
     * @param commit
     * @param state
     * @param getters
     * @param payload
     */
    start({ commit, state }, payload) { // payload should contain start and duration in minutes
        return new Promise((resolve, reject) => {
            // Set the start time and counter
            try {
                commit('set', payload);
            } catch (e) {
                reject('Unable to set start time. \n\r' + e);
            }

            // Make sure it makes sense
            if (state.end.isAfter(state.start) && state.end.isAfter(state.now)) {
                commit('start');
            } else {
                commit('end');
                reject('Invalid timer, end time before the start time or timer already expired.')
                // commit('alerts/newMessage', {
                //     type: 'error',
                //     message: 'Invalid timer, end before start or already ended.'
                // }, {root: true});
                // return;
            }

            commit('start');
            let interval = setInterval(() => {
                commit('increment');

                // Wait for timer to end
                if (moment.utc().isAfter(state.end)) {
                    commit('end');
                    resolve('end');
                }
            }, 1000);
            commit('interval', interval);

            // resolve();
        });
    },
    stop({ commit}) {
        commit('end');
    },
    // timer({ commit }) {
    //     let promise = new Promise((resolve, reject) => {
    //         let interval = setInterval(() => {
    //             commit('increment');
    //
    //             // Wait for timer to end
    //             if (moment.utc().isAfter(state.end)) {
    //                 commit('end');
    //                 resolve('end');
    //             }
    //         }, 1000);
    //         commit('interval', interval);
    //     });
    //
    //     commit('promise', promise);
    // },
};

const mutations = {
    set(state, payload) {
        // Verify start is a Moment object
        state.start = typeof payload.start !== 'object' ? moment.utc(payload.start) : payload.start;
        state.end = state.start.clone().add(payload.duration, 'minutes');//state.end = state.start.clone().add(payload.duration, 'minutes');
    },
    start(state) {
        state.status = 'running';
    },
    increment(state) {
        state.now = moment.utc();
    },
    interval(state, interval) {
        state.interval = interval;
    },
    end(state) {
        state.status = 'expired';
        state.now = moment.utc();
        clearInterval(state.interval);
    },
    // promise(state, promise) {
    //     state.promiser = promise;
    // }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
