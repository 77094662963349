/** store/round.js */
import Vue from 'vue'
import RoundRepository from '../repositories/roundRepository'

// State
const state = {
  rounds: ''
}

const getters = {
  rounds: state => state.rounds,
  activeRounds: state => _.filter(state.rounds, { status: 'active' })
}

const actions = {
  create ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RoundRepository.create(payload)
        .then(r => {
          // Update the state
          commit('setRound', r.data)
          resolve(r.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  fetch ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      RoundRepository.index(rootGetters['facility/bidYear'])
        .then(r => {
          // Update the state
          commit('setRounds', r.data)
          resolve(r)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  delete ({ commit, dispatch }, round) {
    return new Promise((resolve, reject) => {
      RoundRepository.delete(round.id)
        .then(() => {
          // Update the state
          commit('deleteRound', round.id)

          // Update the bidders for the area
          dispatch('bidder/fetch', null, { root: true })
            .then(() => {
              resolve(round)
            })
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  update ({ commit, dispatch, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      RoundRepository.update(payload.round_id, payload)
        .then(r => {
          // Update the state
          commit('setRound', r.data)

          // If round started then fetch new bidders
          const bidders = rootGetters['bidder/roundBidders'](payload.round_id)
          if (payload.status === 'active' && !Object.keys(bidders).length) {
            dispatch('bidder/fetch', null, { root: true })
              .then(() => {
                resolve(r.data)
              })
          } else {
            // Round not started
            resolve(r.data)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  }
}

const mutations = {
  setRounds (state, rounds) {
    state.rounds = rounds
  },
  setRound (state, round) {
    Vue.set(state.rounds, round.id, round)
  },
  deleteRound (state, roundID) {
    delete state.rounds[roundID]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
