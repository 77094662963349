const Layout = () => import('@/views/Layout/_Layout.vue')
const Header = () => import('@/views/Layout/_Header.vue')
const FacilityPage = () => import('@/views/facility/UserFacility.vue')
const FacilityAdmin = () => import('@/views/facility/AdminFacility.vue')
const Messages = () => import('@/views/facility/Messages.vue')
const UserHistory = () => import('@/views/facility/UserHistory.vue')

export default {
  path: '/:facility',
  component: Layout,
  children: [
    {
      path: '/',
      name: 'Facility Home',
      components: { default: FacilityPage, header: Header }
    },
    {
      path: 'admin',
      name: 'Facility Admin',
      meta: {
        loggedIn: true, // TODO: REMOVE - DEPRECATED
        requireLogin: true,
        auth: 'admin'
      },
      components: { default: FacilityAdmin, header: Header }
    },
    {
      path: 'messages',
      name: 'Messages',
      meta: {
        loggedIn: true, // TODO: REMOVE - DEPRECATED
        requireLogin: true,
        auth: 'arearep'
      },
      components: { default: Messages, header: Header }
    },
    {
      path: 'user/:user_id/activity',
      name: 'Activity',
      meta: {
        loggedIn: true, // TODO: REMOVE - DEPRECATED
        requireLogin: true,
        auth: 'arearep'
      },
      components: { default: UserHistory, header: Header }
    }
  ]
}
