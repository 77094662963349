import FacilityService from '../utils/FacilityService'
import { repositoryClass } from './RepositoryClass'

function repo () {
  return repositoryClass('member', `${FacilityService.getId()}/members`)
}
export default {
  index () {
    return repo().get()
  },
  create (payload) {
    return repo().post(payload)
  },
  update (id, payload) {
    return repo().put(id, payload)
  },
  delete (id) {
    return repo().del(id)
  },
  getMember (memberID) {
    return repo().get('getMember', `${memberID}`)
  },
  getMemberSchedule (memberID) {
    return repo().get('getMemberSchedule', `${memberID}/schedule`)
  },
  getAreaMembers (bidYear, areaSlug) {
    return repo().get('getAreaMembers', 'area', { bid_year: bidYear, area_slug: areaSlug })
  },
  import (payload) {
    return repo().post({ ...payload }, 'importMembers', 'import')
  },
  search (criteria) {
    return repo().post(criteria, 'searchMembers', 'search', true, true)
  }
}
